<template>
  <div class="common">
    <form @submit.prevent="onSubmit">
      <div class="common-wrapper flex-grow-1">
        <div v-if="isMobileScreenExclude768" class="common-row">
          <div class="common-header clt-container">
            <div class="common-header__title">
              <RouterLink :to="{ name: 'login' }" class="back-btn" />
              <span class="title-text">Forgot password</span>
            </div>
          </div>
        </div>

        <div class="common-row">
          <div class="clt-container py-4">
            <div class="common-text--big">Forgot your password?</div>
            <div class="common-text--small">Enter your email below to receive your password instructions.</div>
          </div>
        </div>

        <div class="common-row">
          <div class="clt-container">
            <div class="common-group">
              <label for="forgot-email">Email Address</label>
              <input
                id="forgot-email"
                v-model="email"
                autocomplete="username"
                class="font-style"
                :class="{ 'is-invalid': $v.email.$error }"
                name="email"
                type="email"
                @input="emailError = ''"
                @blur="$v.email.$touch()"
              />
            </div>
            <div v-if="$v.email.$error" class="form-dark-bg-error">
              <small v-if="!$v.email.required && $v.email.$dirty" class="form-dark-bg-error__msg"
                >Email field is required.</small
              >
              <small v-else-if="!$v.email.email" class="form-dark-bg-error__msg">Please provide a valid email.</small>
              <small v-else-if="!$v.email.serverFailed" class="form-dark-bg-error__msg">{{ emailError }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="common-wrapper">
        <div class="clt-container text-center py-4">
          <button class="common-button" :class="{ 'clt-disabled': $v.$invalid }" :disabled="$v.$invalid" type="submit">
            Continue
          </button>
        </div>
      </div>
    </form>

    <AppLegalInfoLink class="common-legal-link" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import apiRequest from '@/api/apiRequest';

import AppLegalInfoLink from '@/components/partials/AppLegalInfoLink';

export default {
  name: 'ForgotPasswordPage',
  components: { AppLegalInfoLink },
  metaInfo: {
    title: 'Forgot password',
  },
  data() {
    return {
      email: '',
      emailError: '',
      formSubmitted: false,
    };
  },
  computed: {
    ...mapState(['isMobileScreenExclude768']),
  },
  validations: {
    email: {
      required,
      email,
      serverFailed() {
        return !this.emailError;
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.sendEmailToResetPassword();
    },
    sendEmailToResetPassword() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.emailError = '';

      apiRequest({
        url: 'forgot/send-email',
        method: 'post',
        data: { email: this.email.toLowerCase() },
      })
        .then(() => {
          this.email = '';
          this.$v.$reset();
          this.$toast.success('Email has been sent.');
        })
        .catch(({ response }) => {
          const { status, data: err } = response;
          let error = err.message || 'Unfortunately, email has not been sent. Please contact with the administrator.';

          if (status === 422 && err.errors) {
            error = err.errors.email?.[0] || 'Email validation error.';
            this.emailError = error;
          }

          this.$toast.error(error);
        })
        .finally(() => {
          this.formSubmitted = false;
        });
    },
  },
};
</script>

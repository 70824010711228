<template>
  <a :href="`${marketingSiteBaseUrl}/legal/terms-of-use`" rel="noopener nofollow" target="_blank">Legal</a>
</template>

<script>
import AppConfig from '@/config';

export default {
  name: 'AppLegalInfoLink',
  data() {
    return {
      marketingSiteBaseUrl: AppConfig.getAsString('marketingSiteBaseUrl'),
    };
  },
};
</script>
